<template>
    <div class="Repass">
        <el-card class="card">
            <h3>重置密码</h3>
            <el-form
                ref="form"
                :model="form"
                label-width="100px"
                align="left"
                @submit.native.prevent
                :rules="rules"
                size="small"
            >
                <el-form-item label="企业" prop="company">
                    <el-select style="width: 330px" v-model="form.companyCode">
                        <el-option v-for="c in meta.companies" :label="c.name" :value="c.code" :key="c.code" />
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="form.mobile" />
                </el-form-item>
                <el-form-item label="短信验证码" prop="verifyCode">
                    <el-col :span="13">
                        <el-input style="width: 100%" v-model="form.verifyCode" />
                    </el-col>
                    <el-col class="line" :span="8" :offset="3">
                        <el-button
                            style="width: 100%"
                            ref="btnVerifyCode"
                            @click="handleClkSendVerifyCode"
                            :disabled="sendCodeDisabled"
                            >{{ verifyText }}
                        </el-button>
                    </el-col>
                </el-form-item>
                <el-form-item label="新密码" prop="password">
                    <el-input type="password" v-model="form.password" show-password />
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="form.checkPass" show-password />
                </el-form-item>
                <el-form-item>
                    <el-col :span="11">
                        <el-button type="primary" @click="handleClkResetPass" style="width: 100%"
                            >确认重置密码
                        </el-button>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-button type="reset" style="width: 100%" @click="handleClkCancel">取消</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../js/UrlUtils';
import qs from 'qs';

export default {
    name: 'Repass',
    data() {
        const checkPass = (rule, value, callback) => {
            if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            form: {
                companyCode: '',
                mobile: '',
                verifyCode: '',
                password: '',
                checkPass: '',
            },
            rules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: window.ef.someRegs.mobile, message: '手机号格式不正确', trigger: 'blur' },
                ],
                verifyCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { pattern: /^\d{6}$/, message: '验证码格式不正确', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { pattern: /^[\S]{6,12}$/, message: '密码必须6到18位，且不能出现空格', trigger: 'blur' },
                ],
                checkPass: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { validator: checkPass, trigger: 'blur' },
                ],
            },
            ticker: null,
            verifyText: '发送验证码',
            sendCodeDisabled: false,
            meta: {
                companies: [],
            },
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, '/system/company/list', (data) => {
            this.meta.companies = data;
        });
    },
    methods: {
        handleClkSendVerifyCode() {
            const _this = this;
            if (!this.form.companyCode) {
                this.$message.error('请选择企业');
                return;
            }
            this.$refs['form'].validateField('mobile', (validMessage) => {
                if (validMessage != '') {
                    return false;
                }

                UrlUtils.QueryRemote(
                    this,
                    '/rePassVerifyCode?' +
                        qs.stringify({
                            mobile: this.form.mobile,
                            companyCode: this.form.companyCode,
                        }),
                    (rst) => {
                        let ticker = 60;
                        _this.sendCodeDisabled = true;
                        _this.ticker = setInterval(function () {
                            ticker--;
                            if (ticker <= 0) {
                                _this.verifyText = '发送验证码';
                                _this.sendCodeDisabled = false;
                                clearInterval(_this.ticker);
                                _this.ticker = null;
                            } else {
                                _this.verifyText = ticker + 's';
                            }
                        }, 1000);
                    },
                    (rst) => {
                        this.$message.error(rst);
                        return;
                    }
                );
            });
        },
        handleClkResetPass() {
            const _this = this;
            this.$refs['form'].validate((valid) => {
                if (!valid) {
                    return false;
                }

                UrlUtils.PostRemote(
                    this,
                    '/rePass',
                    this.form,
                    null,
                    (rst) => {
                        _this.$message.success('重置密码成功');
                        setTimeout(function () {
                            _this.$router.push({ name: 'login' });
                        }, 2000);
                    },
                    (rst) => {
                        _this.$message.error(rst);
                    }
                );
            });
        },
        handleClkCancel() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.Repass {
    background-image: url('../assets/login/bg.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.Repass .card {
    width: 460px;
    margin: 0 auto;
    margin-top: 10%;
}

.Repass .cursor {
    font-size: 14px;
    color: #19c989;
    cursor: pointer;
}
</style>
